import { Component, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginPublicService } from '../service/login-public.service';

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.css']
})
export class PatientsComponent implements OnInit, OnDestroy {
  title = 'WayuMD | Consult your Doctor Online | Get medical advice from Specialists';
  url = 'assets/js/slick.js';
  loadAPI;
  public response: any;
  frmSearch: FormGroup;
  loader;
  articles: any;
  routerSubscription: any;
  /* default pic set if doctor image not present in data */
  setDefaultPic = 'assets/img/doctors/noimage.png';

  constructor(private router: Router, private ngZone: NgZone, private renderer: Renderer2, private callGapi: LoginPublicService,
              // tslint:disable-next-line:variable-name
              private spinner: NgxSpinnerService, private _fb: FormBuilder) { }

  ngOnInit() {
    this.frmSearch = this._fb.group({
      searchText: ['', Validators.required]
    });

    this.recallJsFuntions();
    this.spinner.show();
    this.callGapi.isLoad().then((response) => {
      console.log(response);


      this.loader = this.renderer.selectRootElement('#loader');
      this.renderer.removeClass(this.loader, 'hideSpinner');
      this.renderer.addClass(this.loader, 'showSpinner');
      console.log(this.loader);
      console.log(this.callGapi);
      const listData = {page: 1 };
      // this.response = await this.callGapi.hcpSerach(loginData).toPromise();

      // console.log(this.response);
      // Use the JSON data from the HTTP response

      // console.log(this.response);
      // tslint:disable-next-line:no-shadowed-variable
      this.callGapi.allContentList(listData).then((response: any) => {
        const returnedData = response;
        console.log(returnedData);

        if (returnedData.error_code === 0) {
          this.ngZone.run(() => {
            this.renderer.setStyle(this.loader, 'display', 'none');
            const articlesArray = returnedData.articles;
            // tslint:disable-next-line:forin
            for (const index in articlesArray) {
              console.log(articlesArray[index]);
              articlesArray[index].liked = false;
            }
            localStorage.setItem('articles', JSON.stringify(articlesArray));
            this.spinner.hide();
            this.articles = JSON.parse(localStorage.getItem('articles'));
            console.log(this.articles);
            // this.router.navigateByUrl('/search');
          });
        } else {
          this.ngZone.run(() => {
            this.renderer.setStyle(this.loader, 'display', 'none');
            this.spinner.hide();
          });
        }
      });

    });
  }

  /**
   * @description when we navigate from one page to another `scope of js funtions`
   * finished so we have to call it again.
   */
  recallJsFuntions() {
    this.routerSubscription = this.router.events
      .subscribe(event => {
        $.getScript('/assets/js/jquery.min.js');
        // $.getScript('/assets/js/popper.min.js');
        $.getScript('/assets/js/bootstrap.min.js');
        $.getScript('/assets/js/slick.js');
        $.getScript('/assets/js/script.js');
      });
  }

  getSearch() {
    console.log(this.frmSearch.get('searchText').value);
    this.callGapi.isLoad().then((response) => {
      console.log(response);
      this.spinner.show();

      this.loader = this.renderer.selectRootElement('#loader');
      console.log(this.loader);
      console.log(this.callGapi);
      const loginData = { search_string: this.frmSearch.get('searchText').value, page: 1 };
      // this.response = await this.callGapi.hcpSerach(loginData).toPromise();

      // console.log(this.response);
      // Use the JSON data from the HTTP response

      // console.log(this.response);
      // tslint:disable-next-line:no-shadowed-variable
      this.callGapi.hcpSerach(loginData).then((response: any) => {
        const returnedData = response;
        console.log(returnedData);

        if (returnedData.error_code === 0) {
          this.ngZone.run(() => {
            this.renderer.setStyle(this.loader, 'display', 'none');
            localStorage.setItem('hcps', JSON.stringify(returnedData.hcp_search));
            this.spinner.hide();
            // this.router.navigateByUrl('/search');
            const url = this.router.serializeUrl(
              this.router.createUrlTree(['/search'])
            );
            window.open(url, '_blank');
          });
        } else {
          this.ngZone.run(() => {
            this.renderer.setStyle(this.loader, 'display', 'none');
            this.spinner.hide();
          });
        }
      });

    });

  }

  searchDoctor(key: any) {
    console.log(this.frmSearch.get('searchText').value);
    this.callGapi.isLoad().then((response) => {
      console.log(response);
      this.spinner.show();

      const loginData = { search_string: key, page: 1 };
      // tslint:disable-next-line:no-shadowed-variable
      this.callGapi.hcpSerach(loginData).then((response: any) => {
        const returnedData = response;
        console.log(returnedData);

        if (returnedData.error_code === 0) {
          this.ngZone.run(() => {
            localStorage.setItem('hcps', JSON.stringify(returnedData.hcp_search));
            this.spinner.hide();
            // this.router.navigateByUrl('/search');
            const url = this.router.serializeUrl(
              this.router.createUrlTree(['/search'])
            );
            window.open(url, '_blank');
          });
        } else {
          this.ngZone.run(() => {
            this.spinner.hide();
          });
        }
      });

    });

  }

  contentLike(data: any){
    console.log(data);
    this.callGapi.isLoad().then(
      (response) => {
        const likeData = {art_id : data.art_id || 0};
        this.callGapi.contentLike(likeData).then(
          // tslint:disable-next-line:no-shadowed-variable
          (response: any) => {
            const respnseData = response;
            console.log(respnseData);
            this.articles = JSON.parse(localStorage.getItem('articles'));
            if (respnseData.error_code === 0) {
              this.ngZone.run(() => {
                // tslint:disable-next-line:forin
                for (const index in this.articles) {
                  console.log(this.articles[index]);
                  if (this.articles[index].art_id === data.art_id){
                    // tslint:disable-next-line:radix
                    this.articles[index].likes = parseInt(this.articles[index].likes) + 1;
                    this.articles[index].liked = true;
                  }
                }
                this.spinner.hide();
              });
            } else {
              this.ngZone.run(() => {
                this.spinner.hide();
              });
            }
          });
      });
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

}
