import { Injectable } from '@angular/core';
import { GoogleAPIService } from './google-api.service';

@Injectable({
  providedIn: 'root'
})
export class LoginPublicService {

  private gapi;
    constructor(private googleApiService: GoogleAPIService) {
    }

    public isLoad() {
        return this.googleApiService.GetClient().then((gapi) => {
                this.gapi = gapi;
                console.log(this.gapi);
                return true;
            });
    }

    public hcpSerach(data: object){
      return this.gapi.client.loginPublic.publicHCPSearch(data).then(
          // used Arrow function here
          (response) => {
            return response.result;
          });
    }

    public allContentList(data: object){
      return this.gapi.client.loginPublic.publicAllContentList(data).then(
        // used Arrow function here
        (response) => {
          return response.result;
        });
  }

  public contentLike(data:object){
    return this.gapi.client.loginPublic.publicHCPContentLike(data).then(
      (response)=>{
        return response.result;
      }
    );
  }

}
