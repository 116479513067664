		<!-- Footer -->
		<footer class="footer">

			<!-- Footer Top -->
			<div class="footer-top">
				<div class="container-fluid">
					<div class="row">
						

						<div class="col-lg-4 col-md-4 pl-3">

							<!-- Footer Widget -->
							<div class="footer-widget footer-menu">
								<h2 class="footer-title">For Patients
									<div class="line" style="margin-top: 5px;"></div>
								</h2>
								<ul>

								<li><a routerLink="patients" routerLinkActive="active">Consult Doctors online</a></li>
									
									<li><a href="mailto:members@wayumd.com">
										members@wayumd.com</a></li>

										
									<li><a href="https://itunes.apple.com/us/app/wayumd/id1048944622?mt=8" target="_blank"> <img src="assets/img/pat_ios.png" alt="WayuMD" style="width: 150px">
									</a> &nbsp;
									<a href="https://play.google.com/store/apps/details?id=com.wayuhealth.patient" target="_blank"> <img src="assets/img/pat_android.png" alt="WayuMD" style="width: 150px;">
									</a></li>
								</ul>
							</div>
							<!-- /Footer Widget -->

						</div>

						<div class="col-lg-4 col-md-4 pl-3">

							<!-- Footer Widget -->
							<div class="footer-widget footer-menu">
								<h2 class="footer-title">For Doctors
									<div class="line" style="margin-top: 5px;"></div>
								</h2>
								<ul>

								<li><a routerLink="virtualclinic" routerLinkActive="active">Get your virtual clinic</a></li>
									
								<li><a href="mailto:members@wayumd.com">doctors@wayumd.com</a></li>
										
										
									<li><a href="https://apps.apple.com/us/app/wayumd-for-doctors/id1046461435" target="_blank"> <img src="assets/img/doc_ios.png" alt="WayuMD" style="width: 150px">
									</a> &nbsp;
									<a href="https://play.google.com/store/apps/details?id=com.wayuhealth.doctor" target="_blank"> <img src="assets/img/doc_android.png" alt="WayuMD" style="width: 150px;">
									</a></li>
								</ul>
							</div>
							<!-- /Footer Widget -->

						</div>

						<div class="col-lg-4 col-md-4 pl-3">

							<!-- Footer Widget -->
							<div class="footer-widget footer-about">
								<div class="footer-logo">
									<img src="assets/img/wayulogo.png" alt="logo" style="width: 250px;">
								</div>
								<div class="footer-about-content">
									<p>WayuMD is a proprietary healthcare delivery platform developed by Wayu Health, a company dedicated to improving access to healthcare services through technology.  </p>
									<!-- <div class="social-icon">
										<ul>
											<li>
												<a href="#" target="_blank"><i class="fab fa-facebook-f"></i> </a>
											</li>
											<li>
												<a href="#" target="_blank"><i class="fab fa-twitter"></i> </a>
											</li>
											<li>
												<a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a>
											</li>
											<li>
												<a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
											</li>
											<li>
												<a href="#" target="_blank"><i class="fab fa-dribbble"></i> </a>
											</li>
										</ul>
									</div> -->
								</div>
							</div>
							<!-- /Footer Widget -->

						</div>

						<!-- <div class="col-lg-3 col-md-6">

							<div class="footer-widget footer-contact">
								<h2 class="footer-title">Contact Us</h2>
								<div class="footer-contact-info">
									<div class="footer-address">
										<span><i class="fas fa-map-marker-alt"></i></span>
										<p> 3556 Beech Street, San Francisco,<br> California, CA 94108 </p>
									</div>
									<p>
										<i class="fas fa-phone-alt"></i>
										+1 315 369 5943
									</p>
									<p class="mb-0">
										<i class="fas fa-envelope"></i>
										doccure@example.com
									</p>
								</div>
							</div>
							
						</div> -->

					</div>
				</div>
			</div>
			<!-- /Footer Top -->

			<!-- Footer Bottom -->
			<div class="footer-bottom">
				<div class="container-fluid">

					<!-- Copyright -->
					<div class="copyright">
						<div class="row">
							<div class="col-md-6 col-lg-6">
								<div class="copyright-text">
									<p class="mb-0">&copy; Wayu Health All right reserved <span id="copyright">{{currentYear | date :'yyyy'}}</span>.</p>
								</div>
							</div>
							<div class="col-md-6 col-lg-6">

								<!-- Copyright Menu -->
								<div class="copyright-menu">
									<ul class="policy-menu">
										<li><a target="_blank" href="https://wayumd.com/support/terms.html">Terms and Conditions</a></li>
										<li><a target="_blank" href="https://wayumd.com/support/privacy-policy.html" >Privacy Policy</a></li>
										<li><a target="_blank" href="https://wayumd.com/support/faq.html">FAQs</a></li>
									</ul>
								</div>
								<!-- /Copyright Menu -->

							</div>
						</div>
					</div>
					<!-- /Copyright -->

				</div>
			</div>
			<!-- /Footer Bottom -->
			

<ng-template #demoModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Download WayuMD App</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <section class="section section-specialities" id="section-virtual">
    <div class="container">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 offset-md-0">
            <!--Section: Contact v.2-->
            <section class="mb-4">
              <!--Section heading-->
              <h2 class="h1-responsive font-weight-bold text-center my-4">
                Request a demo
              </h2>
              <!--Section description-->
              <p class="text-center w-responsive mx-auto mb-5">
                Do you have any questions? Please do not hesitate to contact us
                directly. Our team will come back to you within a matter of
                hours to help you.
              </p>

              <div class="row">
                <!--Grid column-->
                <div class="offset-md-2 col-md-9 mb-md-0 mb-5">
                <p>Form Status: {{ contactForm.status }}</p>
                  <form [formGroup]="contactForm"
                    (ngSubmit)="sendRequest(contactForm.value)" name="contact-form" method="POST">
                    <!--Grid row-->
                    <div class="row mb-2">
                      <!--Grid column-->
                      <div class="col-md-12">
                        <div class="form-outline form-outline">
                          <label class="form-label" for="name" class=""
                            >Your name</label
                          >
                          <input
                            type="text"
                            id="name"
                            name="name"
                            class="form-control"
                            formControlName="name" required />

                          <app-field-error-display
                            [displayError]="isFieldValid('name')"
                            errorMsg="Please inform your name"
                          >
                          </app-field-error-display>
                        </div>
                      </div>
                      <!--Grid column-->
                    </div>
                    <div class="row mb-2">
                      <!--Grid column-->
                      <div class="col-md-6">
                        <div class="form-outline">
                        <label for="email" class="">Your email</label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            class="form-control"
                            formControlName="name" required
                          />
                          
                        </div>
                      </div>
                      <!--Grid column-->
                      <div class="col-md-6">
                        <div class="form-outline">
                        <label for="mobile" class="">Mobile (with country code)</label>
                          <input
                            type="text"
                            id="mobile"
                            name="mobile"
                            class="form-control"
                            formControlName="mobile" required
                          />
                          
                        </div>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <!--Grid column-->
                      <div class="col-md-6">
                       <label for="name" class="">Organisation name</label>
                        <div class="form-outline">
                          <input
                            type="text"
                            id="name"
                            name="name"
                            class="form-control"
                            formControlName="organisation"
                          />
                         
                        </div>
                      </div>
                      <!--Grid column-->

                      <!--Grid column-->
                      <div class="col-md-6 ">
                        <div class="form-outline">
                        <label for="mobile" class="">Website</label>
                          <input
                            type="text"
                            id="mobile"
                            name="mobile"
                            class="form-control"
                            formControlName="website"
                          />
                          
                        </div>
                      </div>
                      <!--Grid column-->
                    </div>
                    <!--Grid row-->

                    <!--Grid row-->
                    <div class="row mb-2">
                      <div class="col-md-12">
                      <label
                          >What are your main goals for your practice
                          app?</label
                        >
                        <ng-multiselect-dropdown
                          [settings]="dropdownSettings"
                          [data]="dropdownList"
                          [(ngModel)]="selectedItems"
                          formControlName="goal"
                          (onSelect)="onItemSelect($event)"
                          (onSelectAll)="onSelectAll($event)"
                        >
                        </ng-multiselect-dropdown>

                        

                        <!--<mdb-select [options]="optionsSelect" [multiple]="true" [highlightFirst]="false" placeholder="Choose your option"></mdb-select>
                            <label>Example label</label>-->
                      </div>
                    </div>
                    <!--Grid row-->

                    <!--Grid row-->
                    <div class="row mb-4">
                      <!--Grid column-->
                      <div class="col-md-12">
                       <label for="message">Your message</label>
                        <div class="form-outline">
                          <textarea
                            type="text"
                            id="message"
                            name="message"
                            rows="2"
                            class="form-control md-textarea"
                          ></textarea>
                         
                        </div>
                      </div>
                    </div>
                    <!--Grid row-->
                    <div class="text-center text-md-left">
                        <button class="btn btn-primary" type="submit" > Submit </button>
                     </div>
                  </form>

                  
                 
                  <div class="status"></div>
                </div>
                <!--Grid column-->
              </div>
            </section>
            <!--Section: Contact v.2-->
          </div>
        </div>
      </div>
    </div>
  </section>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>

		</footer>
		<!-- /Footer -->