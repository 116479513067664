<header class="header fixed-top">
    <nav class="navbar navbar-expand-lg header-nav">
        <div class="navbar-header" style="min-width: 58%;">
            <a id="mobile_btn" href="javascript:void(0);">
                <span class="bar-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                </span>
            </a>
            <a href="index.html" class="navbar-brand logo" style="width: 200px;text-align: right;">
                <!-- <img src="assets/img/logo.png" class="img-fluid" alt="Logo"> -->
                <img src="assets/img/wayuMD_logo.svg" class="img-fluid" alt="Logo" style="width: 225px;">
            </a>
            <!--<a id="mobile_consult_btn" style="border: 2px solid #FFDF57; background-color: #FFDF57; border-radius: 4px; padding: 5px;
            text-align: center; font-size: 10px; color: #ffffff; text-transform: uppercase; font-weight: 500;" routerLink="patients" routerLinkActive="active">Consult a Doctor Now</a>-->
        </div>
        <div class="main-menu-wrapper">
            <div class="menu-header">
                <a href="index.html" class="menu-logo">
                    <!-- <img src="assets/img/logo.png" class="img-fluid" alt="Logo"> -->
                    <img src="assets/img/wayuMD_logo.svg" class="img-fluid" alt="Logo"
                        style="width: 130px;margin-bottom: 10px; padding:5px">
                </a>
                <a id="menu_close" class="menu-close" href="javascript:void(0);">
                    <i class="fas fa-times"></i>
                </a>
            </div>
            <!-- <ul class="main-nav">
                    <li class="active">
                        <a href="index.html">Home</a>
                    </li>
                    <li class="has-submenu">
                        <a href="#">Doctors <i class="fas fa-chevron-down"></i></a>
                        <ul class="submenu">
                            <li><a href="doctor-dashboard.html">Doctor Dashboard</a></li>
                            <li><a href="appointments.html">Appointments</a></li>
                            <li><a href="schedule-timings.html">Schedule Timing</a></li>
                            <li><a href="my-patients.html">Patients List</a></li>
                            <li><a href="patient-profile.html">Patients Profile</a></li>
                            <li><a href="chat-doctor.html">Chat</a></li>
                            <li><a href="invoices.html">Invoices</a></li>
                            <li><a href="doctor-profile-settings.html">Profile Settings</a></li>
                            <li><a href="reviews.html">Reviews</a></li>
                            <li><a href="doctor-register.html">Doctor Register</a></li>
                        </ul>
                    </li>	
                    <li class="has-submenu">
                        <a href="#">Patients <i class="fas fa-chevron-down"></i></a>
                        <ul class="submenu">
                            <li><a href="search.html">Search Doctor</a></li>
                            <li><a href="doctor-profile.html">Doctor Profile</a></li>
                            <li><a href="booking.html">Booking</a></li>
                            <li><a href="checkout.html">Checkout</a></li>
                            <li><a href="booking-success.html">Booking Success</a></li>
                            <li><a href="patient-dashboard.html">Patient Dashboard</a></li>
                            <li><a href="favourites.html">Favourites</a></li>
                            <li><a href="chat.html">Chat</a></li>
                            <li><a href="profile-settings.html">Profile Settings</a></li>
                            <li><a href="change-password.html">Change Password</a></li>
                        </ul>
                    </li>	
                    <li class="has-submenu">
                        <a href="#">Pages <i class="fas fa-chevron-down"></i></a>
                        <ul class="submenu">
                            <li><a href="voice-call.html">Voice Call</a></li>
                            <li><a href="video-call.html">Video Call</a></li>
                            <li><a href="search.html">Search Doctors</a></li>
                            <li><a href="calendar.html">Calendar</a></li>
                            <li><a href="components.html">Components</a></li>
                            <li class="has-submenu">
                                <a href="invoices.html">Invoices</a>
                                <ul class="submenu">
                                    <li><a href="invoices.html">Invoices</a></li>
                                    <li><a href="invoice-view.html">Invoice View</a></li>
                                </ul>
                            </li>
                            <li><a href="blank-page.html">Starter Page</a></li>
                            <li><a href="login.html">Login</a></li>
                            <li><a href="register.html">Register</a></li>
                            <li><a href="forgot-password.html">Forgot Password</a></li>
                        </ul>
                    </li>
                    <li>
                        <a href="admin/index.html" target="_blank">Admin</a>
                    </li>
                    <li class="login-link">
                        <a href="login.html">Login / Signup</a>
                    </li>
                </ul>		  -->

            <ul class="smooth-scroll main-nav" id="top-menu">
                <!--<li>
                    <a routerLink="home" routerLinkActive="active"> HOME</a>
                </li>-->
                <li><a routerLink="virtualclinic" routerLinkActive="active">Online Clinic</a></li>
                <li><a routerLink="brandedapps" routerLinkActive="active">Branded Apps</a></li>
                 <li class="mobileOnly">
                    <a class="nav-link header-login" routerLink="patients" routerLinkActive="active">Consult a Doctor Now</a>
                </li>
                <!--<li class="has-submenu">
                    <a href="javascript:void(0);" routerLinkActive="active"> For Doctors <i class="fas fa-chevron-down"></i></a>
                    
                    <ul class="submenu">
                            <li><a routerLink="virtualclinic" routerLinkActive="active">VIRTUAL CLINIC</a></li>
                            <li><a routerLink="brandedapps" routerLinkActive="active">brandedapps</a></li>
                            
                        </ul>
                </li>-->

                
                <!-- <li class="has-submenu">
                    <a href="javascript:void(0);" routerLinkActive="active">FOR PATIENTS <i class="fas fa-chevron-down"></i></a>
                    <ul class="submenu">
                            <li><a routerLink="patients" routerLinkActive="active">FOR PATIENTS </a></li>
                            
                        </ul>
                </li> -->
                <!-- <li class="has-submenu">
                        <a href="#">Pages <i class="fas fa-chevron-down"></i></a>
                        <ul class="submenu">
                            <li><a href="voice-call.html">Voice Call</a></li>
                            <li><a href="video-call.html">Video Call</a></li>
                            <li><a href="search.html">Search Doctors</a></li>
                            <li><a href="calendar.html">Calendar</a></li>
                            <li><a href="components.html">Components</a></li>
                            <li class="has-submenu">
                                <a href="invoices.html">Invoices</a>
                                <ul class="submenu">
                                    <li><a href="invoices.html">Invoices</a></li>
                                    <li><a href="invoice-view.html">Invoice View</a></li>
                                </ul>
                            </li>
                            <li><a href="blank-page.html">Starter Page</a></li>
                            <li><a href="login.html">Login</a></li>
                            <li><a href="register.html">Register</a></li>
                            <li><a href="forgot-password.html">Forgot Password</a></li>
                        </ul>
                    </li>
                    <li>
                       <a routerLink="patients" routerLinkActive="active">Consult Now</a>
                    </li>
                    <li class="login-link">
                        <a routerLink="patients" routerLinkActive="active">Consult Now</a>
                    </li>  -->
            </ul>

        </div>
        <ul class="nav header-navbar-rht" style="margin-left:0px;">
            <!-- <li class="nav-item contact-item">
                    <div class="header-contact-img">
                        <i class="far fa-hospital"></i>							
                    </div>
                    <div class="header-contact-detail">
                        <p class="contact-header">Contact</p>
                        <p class="contact-info-header"> +1 315 369 5943</p>
                    </div>
                </li> -->
            <li class="nav-item">
                    <a class="nav-link header-login" routerLink="patients" routerLinkActive="active">Consult a Doctor Now</a>
            </li>
        </ul>
    </nav>
</header>