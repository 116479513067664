import { Component, Renderer2 } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { RouteConfigLoadEnd } from '@angular/router';
import { GoogleApiService } from 'ng-gapi';
import { promise } from 'protractor';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'wayu-website';
  loader;
  constructor(private gapiService: GoogleApiService, private renderer: Renderer2,
              private metaTagService: Meta) {
   // gapiService.onLoad().subscribe((loaded) => console.log(loaded, gapi.load));
      gapiService.onLoad().subscribe((loaded) => {
        // Here we can use gapi
        console.log(gapi);
      });
      this.metaTagService.addTags([
        { name: 'keywords', content: 'Healthcare Technology Platform' },
        { name: 'robots', content: 'index, follow' },
        { name: 'author', content: 'Wayu Health' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { charset: 'UTF-8' }
      ]);
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    // this.renderer.setStyle(this.loader, 'display', 'none');
    this.loader = this.renderer.selectRootElement('#loader');
    this.renderer.addClass(this.loader, 'hideSpinner');
  }
}
