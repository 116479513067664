import { Component, NgZone, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginPublicService } from '../service/login-public.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-searchresults',
  templateUrl: './searchresults.component.html',
  styleUrls: ['./searchresults.component.css']
})
export class SearchresultsComponent implements OnInit {
  public searchResults: any;
  loadAPI;
  public response: any;
  frmSearch: FormGroup;
  loader;
  articles: any;
  routerSubscription: any;
  closeModal: string;
  constructor(private router: Router, private ngZone: NgZone,
              private renderer: Renderer2,
              private callGapi: LoginPublicService,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.searchResults = JSON.parse(localStorage.getItem('hcps'));
    // tslint:disable-next-line:forin
    for (const index in this.searchResults) {
      console.log(this.searchResults[index]);
      // tslint:disable-next-line:max-line-length
      this.searchResults[index].available_days = this.searchResults[index].days_of_week.length > 1 ? this.searchResults[index].days_of_week.split(',') : Array.from(this.searchResults[index].days_of_week ? this.searchResults[index].days_of_week : '');
      console.log(this.searchResults[index].available_days);
    }
    console.log(this.searchResults);
    this.frmSearch = this.fb.group({
      searchText: ['', Validators.required]
    });
  }

  getSearch() {
    console.log(this.frmSearch.get('searchText').value);
    this.callGapi.isLoad().then((response) => {
      console.log(response);
      this.spinner.show();

      this.loader = this.renderer.selectRootElement('#loader');
      this.renderer.removeClass(this.loader, 'hideSpinner');
      this.renderer.addClass(this.loader, 'showSpinner');
      console.log(this.loader);
      console.log(this.callGapi);
      const loginData = { search_string: this.frmSearch.get('searchText').value, page: 1 };
      // this.response = await this.callGapi.hcpSerach(loginData).toPromise();

      // console.log(this.response);
      // Use the JSON data from the HTTP response

      // console.log(this.response);
      // tslint:disable-next-line:no-shadowed-variable
      this.callGapi.hcpSerach(loginData).then((response: any) => {
        const returnedData = response;
        console.log(returnedData);

        if (returnedData.error_code === 0) {
          this.ngZone.run(() => {
            this.renderer.setStyle(this.loader, 'display', 'none');
            const hcpArray = returnedData.hcp_search;
            // tslint:disable-next-line:forin
            for (const index in hcpArray) {
              console.log(hcpArray[index]);
              // tslint:disable-next-line:max-line-length
              hcpArray[index].available_days = hcpArray[index].days_of_week.length > 1 ? hcpArray[index].days_of_week.split(',') : Array.from(hcpArray[index].days_of_week ? hcpArray[index].days_of_week : '');
            }

            localStorage.setItem('hcps', JSON.stringify(hcpArray));
            this.spinner.hide();
            this.searchResults = JSON.parse(localStorage.getItem('hcps'));
            this.router.navigateByUrl('/search');
          });
        } else {
          this.ngZone.run(() => {
            this.renderer.setStyle(this.loader, 'display', 'none');
            this.spinner.hide();
          });
        }
      });

    });

  }

  triggerModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


}
