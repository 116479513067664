import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PlatformsComponent } from './platforms/platforms.component';
import {SearchresultsComponent} from './searchresults/searchresults.component';
import { PatientsComponent } from './patients/patients.component';
import { VirtualClinicComponent } from './virtual-clinic/virtual-clinic.component';
import { ArticlesComponent} from './articles/articles.component';


const routes: Routes = [
  { path: 'articles', component: ArticlesComponent },
  { path: 'patients', component: PatientsComponent},
  { path: 'home', component: HomeComponent },
  { path: 'brandedapps', component: PlatformsComponent },
  { path: 'search', component: SearchresultsComponent },
  { path: 'virtualclinic', component: VirtualClinicComponent},
  { path: '', component: HomeComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
