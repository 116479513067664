import { HttpClient } from '@angular/common/http';
import { Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  currentYear: Date;
  closeModal: string;
  optionsSelect: Array<any>;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  contactForm: FormGroup;
  constructor(private modalService: NgbModal,
              private fb: FormBuilder,
              private http: HttpClient,
              private spinner: NgxSpinnerService,
              private zone: NgZone) { }

  ngOnInit(): void {
    this.currentYear = new Date();
    // alert(this.currentYear.getDate());
    this.dropdownList = [
      { item_id: 1, item_text: 'Expanding my reach and promoting my practice to new patients' },
      { item_id: 2, item_text: 'Increasing patient engagement and retention' },
      { item_id: 3, item_text: 'Online clinic and remote consultations' },
      { item_id: 4, item_text: 'Streamlining my practice operations' },
      { item_id: 5, item_text: 'Providing care through digital tools' },
      { item_id: 6, item_text: 'Other' }
    ];
    this.selectedItems = [];

    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required, Validators.email],
      mobile: ['', Validators.required, Validators.minLength(6)],
      organisation: [''],
      website: [''],
      goal : [''],
    });
    // tslint:disable-next-line:no-unused-expression
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      // allowSearchFilter: true
    };
  }
  triggerModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  public sendRequest(requestValue: any){
    console.log(requestValue);
    this.spinner.show();
    console.log(this.contactForm);
    this.zone.run(() => {
    if (this.contactForm.value) {
      const email = this.contactForm.value;
      this.http.post('https://wayu.in/api/email.php', email)
        .subscribe(
            response => console.log(response),
            response => console.log(response)
        );
      this.spinner.hide();
    } else{
      console.log(requestValue);
      this.spinner.hide();
    }
  });
  }

  isFieldValid(field: string) {
    return !this.contactForm.get(field).valid && this.contactForm.get(field).touched;
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

}
