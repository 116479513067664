		<!-- Home Banner -->
		<section class="section" id="section-search" style="height:auto;">
			<div class="container-fluid row section-search align-items-center" style="margin-left: 0px;margin-right: 0px;">
				<div class="col-md-7 pl-5" style="margin: auto 0;">
					<div class="banner-wrapper">
						<div class="banner-header text-left d-none d-sm-none d-md-block d-lg-block d-xl-block" style="position: absolute;bottom:0;top:-5rem;">
							<div class="footer-logo">
								<!-- <img src="assets/img/wayulogo.png" alt="logo"
									style="width: 280px; margin-bottom: 35px;"> -->
							</div>
							<h1 class="caps">Healthcare’s
								<br />technology 
								<br />platform
							</h1>
							<br />
							<br />
							<p>Offer exceptional care to patients<br/>and boost your practice with convenient
								<br/>access through technology.</p>
						</div>

						<div class="banner-header text-left d-md-none d-lg-none d-xl-none d-xs-block d-sm-block" style="">
							<div class="footer-logo">
								<!-- <img src="assets/img/wayulogo.png" alt="logo"
									style="width: 280px; margin-bottom: 35px;"> -->
							</div>
							<h1 class="caps">Healthcare’s
								<br />technology 
								<br />platform
							</h1>
							<br />
							<div class="d-md-none d-lg-none d-xl-none d-xs-block d-sm-block">
								<img src="../assets/img/home_landing_mobile.png" alt="wayu platform" style="width:100%;">
							</div>
							<br />
							<p>Offer exceptional care to patients<br/>and boost your practice with convenient
								<br/>access through technology.</p>
						</div>

						<!-- Search -->
						<!-- <div class="search-box">
								<form action="templateshub.net">
									<div class="form-group search-location">
										<input type="text" class="form-control" placeholder="Search Location">
										<span class="form-text">Based on your Location</span>
									</div>
									<div class="form-group search-info">
										<input type="text" class="form-control" placeholder="Search Doctors, Clinics, Hospitals, Diseases Etc">
										<span class="form-text">Ex : Dental or Sugar Check up etc</span>
									</div>
									<button type="submit" class="btn btn-primary search-btn"><i class="fas fa-search"></i> <span>Search</span></button>
								</form>
							</div> -->
						<!-- /Search -->

					</div>
				</div>

				<div class="col-md-5 section-search-rightt">

				</div>

			</div>
		</section>
		<!-- /Home Banner -->
		<div class="h-divider">
			<div class="d-shadow"></div>
		</div>

		<!-- <section class="section" style="background:-webkit-linear-gradient(
			90deg , #6F84A1 0%, #6F84A1 100%); padding: 15px;">
			<div class="container-fluid">
				<div class="row align-items-center">
				<div class="banner-wrapper col-md-7">
					<div class="banner-header text-left">
						
					</div>
					 
					
					<div class="search-box text-left">
						
						<form [formGroup]="frmSearch" (ngSubmit)="getSearch()"> 
							
							<div class="row">
								<div class="offset-md-3 col-md-9 text-white" style="padding-left: 0px;">
								<h3 class="text-white">Consult your Doctor online</h3>
								<p>Search by Doctor name, WayuMD ID, speciality or medical conditions and get smarter care online!</p>
								</div>
							<div class="col-md-3"></div>
							<div class="form-group search-info">
								
								<input class="form-control" placeholder="Search by Doctor name, WayuMD ID, speciality or medical conditions" formControlName="searchText" required>
								<div *ngIf="frmSearch.controls['searchText'].invalid && (frmSearch.controls['searchText'].dirty || frmSearch.controls['searchText'].touched)" class="alert alert-danger">
									<div *ngIf="frmSearch.controls['searchText'].errors.required">
									Please enter the search value.
								  </div>
								</div>
						
							</div>
							<button type="submit" [disabled]="frmSearch.pristine || frmSearch.invalid" class="btn btn-primary search-btn"><i class="fas fa-search"></i> <span>Search</span></button>
							</div>
						</form>

						

					</div>
					
				</div>
				<div class="footer-logo col-md-5 align-self-center">
					<div class="banner-header text-left">
						&nbsp;
						
					</div>
					<h3 class="text-white">Download WayuMD for Patients</h3>
					<p class="text-white">Stay connected with your doctor on the WayuMD app, and get smarter care online!</p>
					<a href="https://itunes.apple.com/us/app/wayumd/id1048944622?mt=8" target="_blank"> <img src="assets/img/googleplay_new.png" alt="WayuMD" style="width: 125px; margin-bottom: 25px;">
					</a>
						&nbsp; 
					<a href="https://play.google.com/store/apps/details?id=com.wayuhealth.patient" target="_blank"> <img src="assets/img/appstore_new.png" alt="WayuMD" style="width: 150px; margin-bottom: 25px;">
					</a>
				</div>
				</div>
			</div>
		</section>

		<div class="h-divider">
			<div class="d-shadow"></div>
		</div> -->

		
			<!-- Virtual section starts -->
			<section class="section section-specialities" id="section-virtual">
				<div class="container">
					<div class="container-fluid">
						<!-- <div class="section-header text-right">
							<h2>Virtual Clinic</h2>
							<div class="line" style="float: right;"></div>
						</div> -->
						<div class="row">

							<div class="col-md-6 d-none d-sm-none d-md-block d-lg-block d-xl-block">
								<img src="../assets/img/home_virtual_clinic.png" style="width:100%;">
							</div>

							<!--<div class="col-md-6 virtual-section-left">

							</div>-->

							<div class="col-md-6 relative" style="display: flex; justify-content: center; align-items: center; ">
								<div class="banner-wrapper">
									<div class="banner-header text-left">
										<h2 class="">Expand your reach with your own virtual clinic.</h2>
										<br />

										<div class="d-md-none d-lg-none d-xl-none d-xs-block d-sm-block">
											<img src="../assets/img/home_virtual_clinic.png" style="width:100%;">
										</div>
										<br />
										<p class="text-justify">Set up your Online Clinic on WayuMD, and easily and reliably consult with your
											patients on chat or video calls. WayuMD offers an easy-to-use and secure online
											consultation platform that lets you expand your reach online, attract new
											patients and stay connected with your patient community.</p>
										<br />
										<a href="virtualclinic" style="position:inherit" role="button" class="btn btn-secondary learn-more-btn btn-md">Get your free online clinic</a>
									</div>



								</div>
							</div>
						</div>

					</div>
				</div>
			</section>

			<div class="h-divider d-md-none d-lg-none d-xl-none d-xs-block d-sm-block">
				<div class="d-shadow"></div>
			</div>
			<!-- Virtul section ends-->
			<!-- <hr/> -->

			<!-- Platform section starts-->
			<!-- <hr/> -->
	<section class="section section-doctor" id="section-platform">
				<!-- <div class="comp-header" style="margin-left: 20px;margin-right: 20px;">
		<h2 class="comp-title">Platform</h2>
		<div class="line"></div>
	</div> -->
			<div class="container">
				<div class="container-fluid">
					<!-- <div class="section-header text-left">
						<h2>Platforms</h2>
						<div class="line"></div>
					</div> -->
					<div class="row">

						<div class="col-md-6 relative" style="display: flex; justify-content: center; align-items: center; ">
							<div class="banner-wrapper">
								<div class="banner-header text-left">
									<h2 class="">A personalized telehealth solution can revolutionize your practice.
									</h2>
									<br />
									<div class="d-md-none d-lg-none d-xl-none d-xs-block d-sm-block">
										<img src="../assets/img/WayuMD_web_landing_platform.png" style="width:100%;">
									</div>
									<br />
									<p class="text-justify">Telehealth is not just about video-calls with patients. It is in fact a digital
										front door to your practice, offering patients choices on how they can connect &
										engage with you. A carefully designed and customised telehealth app for your
										practice promotes your services to a wider audience, improves access to care,
										drives engagement, and increases patient loyalty and retention. </p>
									<br />
									<a href="platforms" role="button" style="position:inherit"
										class="btn btn-secondary learn-more-btn btn-md">Launch your own branded app</a>
								</div>

								<!-- Search -->
								<!-- <div class="search-box">
						<form action="templateshub.net">
							<div class="form-group search-location">
								<input type="text" class="form-control" placeholder="Search Location">
								<span class="form-text">Based on your Location</span>
							</div>
							<div class="form-group search-info">
								<input type="text" class="form-control" placeholder="Search Doctors, Clinics, Hospitals, Diseases Etc">
								<span class="form-text">Ex : Dental or Sugar Check up etc</span>
							</div>
							<button type="submit" class="btn btn-primary search-btn"><i class="fas fa-search"></i> <span>Search</span></button>
						</form>
					</div> -->
								<!-- /Search -->

							</div>
						</div>

						<div class="col-md-6 d-none d-sm-none d-md-block d-lg-block d-xl-block">
							<img src="../assets/img/WayuMD_web_landing_platform.png" style="width:100%;">
						</div>

						<!--<div class="col-md-6 section-platform-right-home">

						</div>-->
					</div>


				</div>
			</div>
			</section>
			<!-- <div class="h-divider">
				<div class="d-shadow"></div>
			</div> -->

			<div class="h-divider">
				<div class="d-shadow"></div>
			</div>

			<section class="section section-contact" id="section-platform" style="margin-bottom:2rem;">
				<!-- <div class="comp-header" style="margin-left: 20px;margin-right: 20px;">
					<h2 class="comp-title">Contact Us</h2>
					<div class="line"></div>
				</div> -->
				<div class="container">
					<div class="container-fluid">
						<div class="row">
							<div class="col-md-12 offset-md-0">
            
								<section class="mb-4">
								  
								  <h2 class="h1-responsive font-weight-bold text-center my-4">
									Contact Us 
								  </h2>
								  
								  <p class="text-center w-responsive mx-auto mb-5">
									Get in touch to learn how WayuMD can help you boost your practice
								  </p>
					
								  <div class="row">
									
									<div class="offset-md-2 col-md-9 mb-md-0 mb-5">
									  <form [formGroup]="contactForm" novalidate
										(ngSubmit)="sendRequest(contactForm.value)" name="contact-form" method="POST">
									   
										<div class="row mb-2">
										 
										  <div class="col-md-12">
											<div class="form-outline">
											  <label class="form-label" for="name" class=""
												>Your name</label
											  >
											  <input [ngClass]="displayFieldCss('name')"
												type="text"
												id="name"
												name="name"
												class="form-control"
												formControlName="name" required />
					
											  
											</div>
										  </div>
										  
										</div>
										<div class="row mb-2">
										  
										  <div class="col-md-6">
											<div class="form-outline">
											<label for="email" class="">Your email</label>
											  <input [ngClass]="displayFieldCss('email')"
												type="email"
												id="email"
												name="email"
												class="form-control"
												formControlName="email" required
											  />
											  
											</div>
											
										  </div>
										 
										  <div class="col-md-6">
											<div class="form-outline">
											<label for="mobile" class="">Mobile (with country code)</label>
											  <input [ngClass]="displayFieldCss('mobile')"
												type="text"
												id="mobile"
												name="mobile"
												class="form-control"
												formControlName="mobile" required
											  />  
											</div>
										  </div>
										</div>
					
									   
										<div class="row mb-4">
										  
										  <div class="col-md-12">
										   <label for="message">Your message</label>
											<div class="form-outline">
											  <textarea
												type="text"
												id="message"
												name="message"
												rows="2"
												formControlName="message"
												class="form-control md-textarea"
											  ></textarea>
											 
											</div>
										  </div>
										</div>
									   
										<div class="text-center text-md-left">
											<button class="btn btn-primary" type="submit" > Submit </button>
										 </div>
									  </form>
									 
									  <div class="status"></div>
									</div>
									
								  </div>
								</section>
							   
							  </div>
						</div>
					</div>
				</div>
			</section>