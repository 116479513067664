<section class="section">
  <div
    class="container-fluid row align-items-center"
    style="margin-right: 0px; padding-right: 0px"
  >
    <div class="col-md-12 col-lg-6 col-xs-12 col-sm-12 pl-5" style="margin: auto 0">
      <div class="banner-wrapper">
        <div class="banner-header text-left py-3">
          <h2 class="">Expand your reach with your own virtual clinic.</h2>
          <br />
          <div class="d-md-block d-lg-none d-xl-none d-xs-block d-sm-block">
            <img src="../assets/img/main_virtual_clinic.png" alt="wayu virtual clinic" style="width:100%;">
          </div>
          <p>
            We understand that as a doctor you can offer the best care to your
            patients when you develop long-term relationships with them. WayuMD
            is designed to enable these long-term relationships, and help your
            patients have continuity in care - consulting with you online, or
            visiting you at your clinic.
          </p>
        </div>

       

        <div class="footer-logo">
          <div class="row">
            
                <div class="col-md-4">
                <a href="https://apps.apple.com/us/app/wayumd-for-doctors/id1046461435"
                  target="_blank" > <img src="assets/img/doc_ios.png" alt="WayuMD for Doctor" />
                </a>
                </div>
                &nbsp;
                <div class="col-md-4">
                  <a href="https://play.google.com/store/apps/details?id=com.wayuhealth.doctor"
                    target="_blank" > <img src="assets/img/doc_android.png" alt="WayuMD for Doctor" />
                  </a>
                </div>
          </div>
        </div>

        <!-- Search -->
        <!-- <div class="search-box">
                    <form action="templateshub.net">
                        <div class="form-group search-location">
                            <input type="text" class="form-control" placeholder="Search Location">
                            <span class="form-text">Based on your Location</span>
                        </div>
                        <div class="form-group search-info">
                            <input type="text" class="form-control" placeholder="Search Doctors, Clinics, Hospitals, Diseases Etc">
                            <span class="form-text">Ex : Dental or Sugar Check up etc</span>
                        </div>
                        <button type="submit" class="btn btn-primary search-btn"><i class="fas fa-search"></i> <span>Search</span></button>
                    </form>
                </div> -->
        <!-- /Search -->
      </div>
    </div>

    <div class="col-md-12 col-lg-6 col-xs-12 col-sm-12 d-none d-lg-block d-xl-block section-virtual-right" id="section-virtual-right"></div>
  </div>
</section>
<!-- /Home Banner -->
<!-- Virtual section starts -->
<div class="h-divider" style="margin-top: 45px !important">
  <div class="d-shadow"></div>
</div>
<div class="container" style="margin-top: 12rem">
  <section
    class="section section-features"
    id="section-virtual"
    style="margin-bottom: 40px"
  >
    <div class="container-fluid">
      <!-- <div class="section-header text-left">
    <h2>Virtual Clinic</h2>
    <div class="line"></div>
</div> -->
      <div class="row">
        <div class="col-md-12 col-lg-4 relative">
          <div class="banner-wrapper card" style="padding-top: 15px;position:relative;">
            <div class="text-center" style="min-height: 80px">
              <img
                src="assets/img/income.png"
                alt="Engagement"
                style="width: 160px;position:absolute;
                margin-bottom: 20px;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                top: -20%;
                background: white;
                padding: 8px;
                border-radius: 50%;
                "
                class="img-fluid shadow"
              />
            </div>
            <div class="p-3" style="min-height: 415px">
              <h3 class="caps text-center" style="font-size: 2rem;">
                Enjoy <br/>Higher Incomes
              </h3>
              <div class="text-left p-2">
                <p class="text-center" style="font-size: 1.23rem;">
                  An online clinic increases your patient interactions, while
                  keeping costs low. The WayuMD app helps you set up a virtual
                  clinic that is an extension of your existing practice - with
                  your own fees and availability.
                </p>
                <!-- <br/>
                  <a href="" role="button" class="btn btn-secondary learn-more-btn">Learn More</a> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 d-md-block d-lg-none d-xl-none d-xs-block d-sm-block m-5 p-2">
          &nbsp;
        </div>
        <div class="col-md-12 col-lg-4 relative">
          <div class="banner-wrapper card" style="padding-top: 15px;position:relative">
            <div class="text-center" style="min-height: 80px">
              <img
                src="assets/img/reach.png"
                alt="Retention"
                style="
                width: 160px;position:absolute;
                margin-bottom: 20px;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                top: -20%;
                background: white;
                padding: 8px;
                border-radius: 50%;
                "
                class="img-fluid shadow"
              />
            </div>
            <div class="p-3" style="min-height: 415px">
              <h3 class="caps text-center" style="font-size: 2rem;">
                Expand <br/>your Reach
              </h3>
              <div class="text-left p-2">
                <p class="text-center" style="font-size: 1.23rem;">
                  Expand your practice beyond your local geography, and offer care
                  and advice to remotely located patients. The best practitioners
                  around the world utilise digital tools to grow their practice.
                </p>
                <!-- <br/>
                  <a href="" role="button" class="btn btn-secondary learn-more-btn">Learn More</a> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 d-md-block d-lg-none d-xl-none d-xs-block d-sm-block m-5 p-2">
          &nbsp;
        </div>
        <div class="col-md-12 col-lg-4 relative">
          <div class="banner-wrapper card" style="padding-top: 15px;position:relative;">
            <div class="text-center" style="min-height: 80px">
              <img
                src="assets/img/care.png"
                alt="Growth"
                style="
                  width: 160px;position:absolute;
                  margin-bottom: 20px;
                  left: 0;
                  right: 0;
                  margin-left: auto;
                  margin-right: auto;
                  top: -20%;
                  background: white;
                  padding: 8px;
                  border-radius: 50%;
                "
                class="img-fluid shadow"
              />
            </div>
            <div class="p-3" style="min-height: 415px">
              <h3 class="caps text-center" style="font-size: 2rem;">
                Enhance <br/>Quality of Care
              </h3>
              <div class="text-left p-2">
                <p class="text-center" style="font-size: 1.23rem;">
                  WayuMD apps make patient visits more informative & efficient
                  with medical profiles, health data and histories. Staying
                  connected with their doctors has shown to improve patients’
                  engagement with their own health.
                </p>
                <!-- <br/>
                  <a href="" role="button" class="btn btn-secondary learn-more-btn">Learn More</a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- platform section-->

  <div class="h-divider" style="position: absolute; left: 0">
    <div class="d-shadow"></div>
  </div>
  <div class="col-md-12 d-md-block d-lg-none d-xl-none d-xs-block d-sm-block m-5">
    &nbsp;
  </div>

  <!-- Virtual section starts -->
  <section class="section section-specialities" id="section-virtual">
    <div class="container">
      <div class="container-fluid">
        <!-- <div class="section-header text-right">
                    <h2>Virtual Clinic</h2>
                    <div class="line" style="float: right;"></div>
                </div> -->
                <div class="row mb-4">
                    <div class="col-md-12 col-lg-8">
                        <h3 class="mb-2" style="font-size:2rem"> Your Online Clinic, on WayuMD. </h3>
                        <p style="font-size:1.23rem"> The WayuMD Online Clinic plan is for doctors who want to quickly and easily set up a virtual practice, and start offering online consults. It is free to sign up on the WayuMD for Doctors app, and create your online clinic profile.  </p>
                    </div>
                </div>
        <div class="row">
          <div class="col-md-6 module-section-left-virtual"></div>

          <div class="col-md-6 relative align-self-center">
            <div class="banner-wrapper">
              <div class="banner-header text-left">
                <div class="flex-fill">
                  <!-- <div class="card-header">
                                        <h4 class="card-title">Text element</h4>
                                    </div> -->
                  <div class="card-body">
                    <p><strong>Online Consults</strong></p>
                    <p style="font-size: 15px; padding-top: 12px">
                      Consult remotely with patients via private and secure chat
                      or video calls.
                    </p>
                    <br />
                    <p><strong>Personalised & flexible</strong></p>
                    <p style="font-size: 15px; padding-top: 12px">
                      Set your own fees and availability, and offer online
                      consults as per your schedule.
                    </p>
                    <br />
                    <p><strong>Medical Records </strong></p>
                    <p style="font-size: 15px; padding-top: 12px">
                      Create online prescriptions and record clinical
                      interactions with easy to use templates.
                    </p>
                    <br />
                    <p><strong>Boost your profile</strong></p>
                    <p style="font-size: 15px; padding-top: 12px">
                      Share health tips and your expertise with the patient
                      community and boost your profile.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--<div class="h-divider" style="position: absolute; left: 0">
    <div class="d-shadow"></div>
  </div>-->

  <!-- Request a demo section-->

  
</div>
