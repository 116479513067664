<!-- Home Banner -->
<section class="section">
    <div class="container-fluid row align-items-center">
        <div class="col-md-7 pl-5">
            <div class="banner-header text-left">
                <h1>Consult your Doctor online</h1>
                <p style="font-size:1.2rem;">Stay connected with your doctor on the WayuMD app, 
                <br/>and get smarter care online!</p>

                <div class="d-md-none d-lg-none d-xl-none d-xs-block d-sm-block">
                    <img src="../assets/img/main_patients.png" style="width:100%;">
                </div>

            </div>

            <!-- Search -->
            <div class="search-box">
                <form [formGroup]="frmSearch" (ngSubmit)="getSearch()" style="margin-top:4rem;margin-bottom:2rem;"> 
                    <!-- <div class="form-group search-location">
                        <input type="text" class="form-control" placeholder="Search Location">
                        <span class="form-text">Based on your Location</span>
                    </div> -->
                    <div class="form-group search-info">
                        <!-- <input type="text" > -->
                        <input class="form-control" placeholder="Search by Doctor name, WayuMD ID, speciality or medical conditions" formControlName="searchText" required>
                        <div *ngIf="frmSearch.controls['searchText'].invalid && (frmSearch.controls['searchText'].dirty || frmSearch.controls['searchText'].touched)" class="alert alert-danger">
                            <div *ngIf="frmSearch.controls['searchText'].errors.required">
                            Please enter the search value.
                          </div>
                        </div>
                
                    </div>
                    <button type="submit" [disabled]="frmSearch.pristine || frmSearch.invalid" class="btn btn-primary search-btn"><i class="fas fa-search"></i> <span>Search</span></button>
                </form>
                <div class="container pl-0">
                    <div class="row">
                      <div class="col-xs-5 col-sm-5 col-md-3">
                        <a href="https://itunes.apple.com/us/app/wayumd/id1048944622?mt=8" target="_blank"> <img src="assets/img/pat_ios.png" alt="WayuMD" style="width: 170px; margin-bottom: 25px;">
                        </a>
                      </div>
                      <div class="col-xs-5 col-sm-5 col-md-4">
                        <a href="https://play.google.com/store/apps/details?id=com.wayuhealth.patient" target="_blank"> <img src="assets/img/pat_android.png" alt="WayuMD" style="width: 170px; margin-bottom: 25px;">
                        </a>
                      </div>
                      
                    </div>
                </div>
                <!--<div class="row">
                    <div class="row col-md-12">
            
                        <div class="col-xs-4 col-sm-4 col-md-4">
                            <a href="https://itunes.apple.com/us/app/wayumd/id1048944622?mt=8" target="_blank"> <img src="assets/img/pat_ios.png" alt="WayuMD" style="width: 170px; margin-bottom: 25px;">
                            </a>
                        </div>
                        <div class="col-xs-4 col-sm-4 col-md-4">
                            <a href="https://play.google.com/store/apps/details?id=com.wayuhealth.patient" target="_blank"> <img src="assets/img/pat_android.png" alt="WayuMD" style="width: 170px; margin-bottom: 25px;">
                            </a>
                        </div>
                    </div>
                </div>-->
            </div>
            <!-- /Search -->

        </div>

        <div class="col-md-5 d-none d-sm-none d-md-block">
            <img src="../assets/img/main_patients.png" style="width:100%;">
        </div>

    </div>
    
</section>
<!-- /Home Banner -->
<div class="h-divider">
    <div class="d-shadow"></div>
</div>

<!-- Clinic and Specialities -->
<section class="section section-specialities">
    <div class="container-fluid">
        <div class="section-header text-center">
            <h2>Frequently Consulted
                <div class="center-line"></div>
            </h2>
            <!-- <p class="sub-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua.</p> -->
        </div>
        <div class="d-flex align-items-center">
            
            <div class="row mx-auto">
                <!-- Slider -->
                <div class="specialities-slider slider slick-initialized slick-slider slick-dotted">
                    <div class="slick-list draggable">
                        <div class="slick-track"
                            style="opacity: 1;">
                            <div class="col-md-2 slick-slide slick-cloned" data-slick-index="-1" aria-hidden="true"
                                tabindex="-1">
                                <div>
                                    <div class="speicality-item text-center" (click)="searchDoctor('paediatrics')"
                                        style="width: 100%; display: inline-block;cursor: pointer;">
                                        <div class="speicality-img m-auto">
                                            <img src="assets/img/specialities/paediatrics_2.png" class="img-fluid"
                                                alt="Speciality" style="width: 110px;">
                                            <!-- <span><i class="fa fa-circle" aria-hidden="true"></i></span> -->
                                        </div>
                                        <p>Paediatrics</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 slick-slide" data-slick-index="0" aria-hidden="true" role="tabpanel"
                                id="slick-slide00" tabindex="-1" aria-describedby="slick-slide-control00">
                                <div>
                                    <div class="speicality-item text-center" (click)="searchDoctor('women health')"
                                        style="width: 100%; display: inline-block; cursor: pointer;">
                                        <div class="speicality-img m-auto">
                                            <img src="assets/img/specialities/womenhealth.png" class="img-fluid"
                                                alt="Speciality" style="width: 110px;">
                                            <!-- <span><i class="fa fa-circle" aria-hidden="true"></i></span> -->
                                        </div>
                                        <p>Women's Health</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 slick-slide slick-current slick-active" data-slick-index="1" aria-hidden="false"
                                role="tabpanel" id="slick-slide01" aria-describedby="slick-slide-control01">
                                <div>
                                    <div class="speicality-item text-center" (click)="searchDoctor('mental wellness')"
                                        style="width: 100%; display: inline-block; cursor: pointer;">
                                        <div class="speicality-img m-auto">
                                            <img src="assets/img/specialities/mental wellness.png" class="img-fluid"
                                                alt="Speciality" style="width: 110px;">
                                            <!-- <span><i class="fa fa-circle" aria-hidden="true"></i></span> -->
                                        </div>
                                        <p>Mental Wellness</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 slick-slide" data-slick-index="2" aria-hidden="true" tabindex="-1"
                                role="tabpanel" id="slick-slide02" aria-describedby="slick-slide-control02">
                                <div>
                                    <div class="speicality-item text-center" (click)="searchDoctor('heart health')"
                                        style="width: 100%; display: inline-block; cursor: pointer;">
                                        <div class="speicality-img m-auto">
                                            <img src="assets/img/specialities/heart health.png" class="img-fluid"
                                                alt="Speciality" style="width: 110px;">
                                            <!-- <span><i class="fa fa-circle" aria-hidden="true"></i></span> -->
                                        </div>
                                        <p>Heart Health</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 slick-slide" data-slick-index="3" aria-hidden="true" tabindex="-1"
                                role="tabpanel" id="slick-slide03" aria-describedby="slick-slide-control03">
                                <div>
                                    <div class="speicality-item text-center" (click)="searchDoctor('diabetes')"
                                        style="width: 100%; display: inline-block;cursor: pointer;">
                                        <div class="speicality-img m-auto text-center">
                                            <img src="assets/img/specialities/lifestyle_2.png" class="img-fluid"
                                                alt="Speciality" style="width: 110px;">
                                            <!-- <span><i class="fa fa-circle" aria-hidden="true"></i></span> -->
                                        </div>
                                        <p>Diabetes</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 slick-slide" data-slick-index="4" aria-hidden="true" tabindex="-1"
                                role="tabpanel" id="slick-slide04" aria-describedby="slick-slide-control04">
                                <div>
                                    <div class="speicality-item text-center" (click)="searchDoctor('ayurveda')"
                                        style="width: 100%; display: inline-block; cursor: pointer;">
                                        <div class="speicality-img m-auto text-center">
                                            <img src="assets/img/specialities/ayurveda.png" class="img-fluid"
                                                alt="Speciality" style="width: 110px;">
                                            <!-- <span><i class="fa fa-circle" aria-hidden="true"></i></span> -->
                                        </div>
                                        <p>Ayurveda & Homeopathy</p>
                                    </div>
                                </div>
                            </div>
                            
                            
                            
                        </div>
                    </div>
                    <!-- <ul class="slick-dots" style="" role="tablist">
                        <li class="" role="presentation"><button type="button" role="tab" id="slick-slide-control00"
                                aria-controls="slick-slide00" aria-label="1 of 5" tabindex="-1">1</button></li>
                        <li role="presentation" class="slick-active"><button type="button" role="tab"
                                id="slick-slide-control01" aria-controls="slick-slide01" aria-label="2 of 5"
                                tabindex="0" aria-selected="true">2</button></li>
                        <li role="presentation"><button type="button" role="tab" id="slick-slide-control02"
                                aria-controls="slick-slide02" aria-label="3 of 5" tabindex="-1">3</button></li>
                        <li role="presentation"><button type="button" role="tab" id="slick-slide-control03"
                                aria-controls="slick-slide03" aria-label="4 of 5" tabindex="-1">4</button></li>
                        <li role="presentation"><button type="button" role="tab" id="slick-slide-control04"
                                aria-controls="slick-slide04" aria-label="5 of 5" tabindex="-1">5</button></li>
                    </ul> -->
                </div>
                <!-- /Slider -->

            </div>
        </div>
    </div>
</section>
<!-- Clinic and Specialities -->
<div class="h-divider">
    <div class="d-shadow"></div>
</div>
<!-- Popular Section -->
<section class="section section-doctor" style="padding-top: 10px;">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <h2 class="text-center" style="margin-bottom: 40px; margin-top: 20px;">Health Feeds
                <div class="center-line"></div>
            </h2>
            
            <!--<div class="col-lg-1">
               
            </div>-->
            <div class="col-md-10 col-lg-10 col-xl-10">
                    <div class="doc-review review-listing">
                    
                        <!-- Review Listing -->

                        <ul class="comments-list row">
                        
                            <!-- Comment List -->
                            <li class="col-md-4 col-sm-6 col-xs-12" *ngFor="let art of articles; let i = index" style="border:none;padding:10px;margin-top: 0px;">
                                <div class="comment" style="border:1px solid #f3f3f3; border-radius:5px;padding:10px;">
                                    <img class="avatar rounded-circle" alt="User Image" [src]="art.serving_url" onError="this.src='assets/img/doctors/noimage.png'">
                                    <div class="comment-body" style="width: 100%;">
                                        <div class="meta-data">
                                            <span class="comment-author pointer" (click)="searchDoctor(art.first_name)">{{art.hcp_title}} {{art.first_name}} {{art.last_name}}</span>
                                            <span class="comment-date text-info pointer" (click)="searchDoctor(art.specialty)">{{art.specialty}}</span>
                                            <div class="review-count rating">
                                                <!-- <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star filled"></i>
                                                <i class="fas fa-star"></i> -->
                                                <a href="javascript:void(0)" (click)="contentLike(art)" *ngIf="art.liked === false" style="margin-top: -4px;">
                                                    <i class="far fa-heart" style="color: #010101;"></i>
                                                </a>
                                                <i class="fas fa-heart" style="color: #F55154" *ngIf="art.liked === true"></i>
                                                <span class="d-inline-block average-rating" style="font-size: 12px;
                                                display: inline-block;
                                                margin-top: -3px;
                                                margin-left: 4px;">({{art.likes}})</span>
                                            </div>
                                        </div>
                                        <p class="recommended"><i class="far fa-thumbs-up"></i> {{art.publish_date | date:'dd MMM'}}</p>
                                        <p class="comment-content" style="width: 100%;min-height: 41px;">
                                            {{ (art.content.length>50)? (art.content | slice:0:50)+'...':(art.content) }}
                                        </p>
                                        <div class="comment-reply">
                                            <!-- <a class="comment-btn" href="#">
                                                <i class="fas fa-reply"></i> Reply
                                            </a> -->
                                           <!-- <p class="recommend-btn">
                                          
                                                <a href="javascript:void(0)" (click)="contentLike(art)" class="like-btn">
                                                    <i class="far fa-heart"></i>
                                                </a>
                                           
                                            </p> -->
                                        </div>
                                    </div>
                                </div>
                                
                               
                            </li>
                            
                            
                        </ul>

                       
                        <!-- /Comment List -->
                        
                    </div>
                </div>
                <!-- <div class="doctor-slider slider slick-initialized slick-slider"><button class="slick-prev slick-arrow"
                        aria-label="Previous" type="button" style="">Previous</button>
                    <div class="slick-list draggable">
                        <div class="slick-track"
                            style="opacity: 1; width: 40000px; transform: translate3d(-280px, 0px, 0px);">
                            <div class="slick-slide slick-cloned" *ngFor="let art of articles" data-slick-index="-1" aria-hidden="true"
                                tabindex="-1">
                                <div>
                                    <div class="profile-widget" style="width: 100%; display: inline-block;">
                                        <div class="doc-img">
                                            <a href="doctor-profile.html" tabindex="-1">
                                                <img class="img-fluid" alt="User Image" style="max-height: 210px;min-height: 210px;"
                                                [src]="art.serving_url" (error)="setDefaultPic()">
                                            </a>
                                            <a href="javascript:void(0)" class="fav-btn" tabindex="-1">
                                                <i class="far fa-bookmark"></i>
                                            </a>
                                        </div>
                                        <div class="pro-content">
                                            <h3 class="title">
                                                <a href="doctor-profile.html" tabindex="-1">{{art.hcp_title}} {{art.first_name}} {{art.last_name}}</a>
                                                <i class="fas fa-check-circle verified"></i>
                                            </h3>
                                            <p class="speciality">{{art.specialty}}</p>
                                            <p class="speciality" style="color: #777;">{{art.content}}</p>
                                            <div class="row" style="padding: 0px 10px 0px 15px;">
                                                <div class="rating col-md-6">
                                                    <span class="d-inline-block average-rating">{{art.publish_date | date:'dd MMM'}}</span>
                                                </div>
                                                <div class="rating col-md-6 text-right">
                                                    <i class="fas fa-heart" style="color: rgba(141, 27, 27, 0.911);"></i>
                                                    
                                                    <span class="d-inline-block average-rating">({{art.likes}})</span>
                                                </div>
                                            </div>
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><button class="slick-next slick-arrow" aria-label="Next" type="button" style="">Next</button>
                </div> -->
        </div>
    </div>
</section>
<!-- /Popular Section -->

<!-- Availabe Features -->
<!-- <section class="section section-features">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-5 features-img">
                <img src="assets/img/features/feature.png" class="img-fluid" alt="Feature">
            </div>
            <div class="col-md-7">
                <div class="section-header">
                    <h2 class="mt-2">Availabe Features in Our Clinic</h2>
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page
                        when looking at its layout. </p>
                </div>
                <div class="features-slider slider slick-initialized slick-slider slick-dotted">
                    <div class="slick-list draggable" style="padding: 0px 50px;">
                        <div class="slick-track"
                            style="opacity: 1; width: 30000px; transform: translate3d(-396px, 0px, 0px);">
                            <div class="slick-slide slick-cloned" data-slick-index="-4" aria-hidden="true"
                                tabindex="-1">
                                <div>
                                    <div class="feature-item text-center" style="width: 100%; display: inline-block;">
                                        <img src="assets/img/features/feature-03.jpg" class="img-fluid" alt="Feature">
                                        <p>ICU</p>
                                    </div>
                                </div>
                            </div>
                            <div class="slick-slide slick-cloned" data-slick-index="-3" aria-hidden="true"
                                tabindex="-1">
                                <div>
                                    <div class="feature-item text-center" style="width: 100%; display: inline-block;">
                                        <img src="assets/img/features/feature-04.jpg" class="img-fluid" alt="Feature">
                                        <p>Laboratory</p>
                                    </div>
                                </div>
                            </div>
                            <div class="slick-slide slick-cloned" data-slick-index="-2" aria-hidden="true"
                                tabindex="-1">
                                <div>
                                    <div class="feature-item text-center" style="width: 100%; display: inline-block;">
                                        <img src="assets/img/features/feature-05.jpg" class="img-fluid" alt="Feature">
                                        <p>Operation</p>
                                    </div>
                                </div>
                            </div>
                            <div class="slick-slide slick-cloned slick-active" data-slick-index="-1" aria-hidden="false"
                                tabindex="-1">
                                <div>
                                    <div class="feature-item text-center" style="width: 100%; display: inline-block;">
                                        <img src="assets/img/features/feature-06.jpg" class="img-fluid" alt="Feature">
                                        <p>Medical</p>
                                    </div>
                                </div>
                            </div>
                            <div class="slick-slide slick-current slick-active slick-center" data-slick-index="0"
                                aria-hidden="false" role="tabpanel" id="slick-slide20">
                                <div>
                                    <div class="feature-item text-center" style="width: 100%; display: inline-block;">
                                        <img src="assets/img/features/feature-01.jpg" class="img-fluid" alt="Feature">
                                        <p>Patient Ward</p>
                                    </div>
                                </div>
                            </div>
                            <div class="slick-slide slick-active" data-slick-index="1" aria-hidden="false"
                                role="tabpanel" id="slick-slide21">
                                <div>
                                    <div class="feature-item text-center" style="width: 100%; display: inline-block;">
                                        <img src="assets/img/features/feature-02.jpg" class="img-fluid" alt="Feature">
                                        <p>Test Room</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 slick-slide" data-slick-index="2" aria-hidden="true" role="tabpanel"
                                id="slick-slide22">
                                <div>
                                    <div class="feature-item text-center" style="width: 100%; display: inline-block;">
                                        <img src="assets/img/features/feature-03.jpg" class="img-fluid" alt="Feature">
                                        <p>ICU</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 slick-slide" data-slick-index="3" aria-hidden="true" tabindex="-1"
                                role="tabpanel" id="slick-slide23">
                                <div>
                                    <div class="feature-item text-center" style="width: 100%; display: inline-block;">
                                        <img src="assets/img/features/feature-04.jpg" class="img-fluid" alt="Feature">
                                        <p>Laboratory</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 slick-slide" data-slick-index="4" aria-hidden="true" tabindex="-1"
                                role="tabpanel" id="slick-slide24">
                                <div>
                                    <div class="feature-item text-center" style="width: 100%; display: inline-block;">
                                        <img src="assets/img/features/feature-05.jpg" class="img-fluid" alt="Feature">
                                        <p>Operation</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 slick-slide" data-slick-index="5" aria-hidden="true" tabindex="-1"
                                role="tabpanel" id="slick-slide25">
                                <div>
                                    <div class="feature-item text-center" style="width: 100%; display: inline-block;">
                                        <img src="assets/img/features/feature-06.jpg" class="img-fluid" alt="Feature">
                                        <p>Medical</p>
                                    </div>
                                </div>
                            </div>
                            <div class="slick-slide slick-cloned" data-slick-index="6" aria-hidden="true" tabindex="-1">
                                <div>
                                    <div class="feature-item text-center" style="width: 100%; display: inline-block;">
                                        <img src="assets/img/features/feature-01.jpg" class="img-fluid" alt="Feature">
                                        <p>Patient Ward</p>
                                    </div>
                                </div>
                            </div>
                            <div class="slick-slide slick-cloned" data-slick-index="7" aria-hidden="true" tabindex="-1">
                                <div>
                                    <div class="feature-item text-center" style="width: 100%; display: inline-block;">
                                        <img src="assets/img/features/feature-02.jpg" class="img-fluid" alt="Feature">
                                        <p>Test Room</p>
                                    </div>
                                </div>
                            </div>
                            <div class="slick-slide slick-cloned slick-center" data-slick-index="8" aria-hidden="true"
                                tabindex="-1">
                                <div>
                                    <div class="feature-item text-center" style="width: 100%; display: inline-block;">
                                        <img src="assets/img/features/feature-03.jpg" class="img-fluid" alt="Feature">
                                        <p>ICU</p>
                                    </div>
                                </div>
                            </div>
                            <div class="slick-slide slick-cloned" data-slick-index="9" aria-hidden="true" tabindex="-1">
                                <div>
                                    <div class="feature-item text-center" style="width: 100%; display: inline-block;">
                                        <img src="assets/img/features/feature-04.jpg" class="img-fluid" alt="Feature">
                                        <p>Laboratory</p>
                                    </div>
                                </div>
                            </div>
                            <div class="slick-slide slick-cloned" data-slick-index="10" aria-hidden="true"
                                tabindex="-1">
                                <div>
                                    <div class="feature-item text-center" style="width: 100%; display: inline-block;">
                                        <img src="assets/img/features/feature-05.jpg" class="img-fluid" alt="Feature">
                                        <p>Operation</p>
                                    </div>
                                </div>
                            </div>
                            <div class="slick-slide slick-cloned" data-slick-index="11" aria-hidden="true"
                                tabindex="-1">
                                <div>
                                    <div class="feature-item text-center" style="width: 100%; display: inline-block;">
                                        <img src="assets/img/features/feature-06.jpg" class="img-fluid" alt="Feature">
                                        <p>Medical</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul class="slick-dots" style="" role="tablist">
                        <li class="slick-active" role="presentation"><button type="button" role="tab"
                                id="slick-slide-control20" aria-controls="slick-slide20" aria-label="1 of 2"
                                tabindex="0" aria-selected="true">1</button></li>
                        <li role="presentation"><button type="button" role="tab" id="slick-slide-control21"
                                aria-controls="slick-slide21" aria-label="2 of 2" tabindex="-1">2</button></li>
                        <li role="presentation"><button type="button" role="tab" id="slick-slide-control22"
                                aria-controls="slick-slide22" aria-label="3 of 2" tabindex="-1">3</button></li>
                        <li role="presentation"><button type="button" role="tab" id="slick-slide-control23"
                                aria-controls="slick-slide23" aria-label="4 of 2" tabindex="-1">4</button></li>
                        <li role="presentation"><button type="button" role="tab" id="slick-slide-control24"
                                aria-controls="slick-slide24" aria-label="5 of 2" tabindex="-1">5</button></li>
                        <li role="presentation"><button type="button" role="tab" id="slick-slide-control25"
                                aria-controls="slick-slide25" aria-label="6 of 2" tabindex="-1">6</button></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- Availabe Features -->