import { Component, NgZone, OnInit, Renderer2, ElementRef } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginPublicService } from '../service/login-public.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  [x: string]: any;
  title = 'WayuMD.com - WayuMD - Healthcare Technology Platform';
  public response: any;
  frmSearch: FormGroup;
  loader;
  contactForm: FormGroup;
  // tslint:disable-next-line:variable-name
  constructor(private router: Router, private callGapi: LoginPublicService, private _fb: FormBuilder,
              private renderer: Renderer2, private ngZone: NgZone, private el: ElementRef,
              private http: HttpClient, private spinner: NgxSpinnerService,
              private titleService: Title, private metaTagService: Meta) { }
  ngOnInit(): void {
    // this.callGapi.isLoad();
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Healthcare Technology Platform' }
    );
    this.loader = this.renderer.selectRootElement('#loader');
    console.log(this.callGapi.isLoad());
    this.callGapi.isLoad().then(status => {
      console.log(status);
    });
    this.frmSearch = this._fb.group({
      searchText: ['', Validators.required]
    });

    this.contactForm = this._fb.group({
      name: ['', Validators.required ],
      email: ['', [Validators.required, Validators.email] ],
      mobile: ['', Validators.required ],
      message : '',
    });

  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    // this.renderer.setStyle(this.loader, 'display', 'none');
    this.loader = this.renderer.selectRootElement('#loader');
    this.renderer.addClass(this.loader, 'hideSpinner');
  }

  public sendRequest(requestValue: any){
    console.log(requestValue);
    this.spinner.show();
    console.log(this.contactForm);
    this.ngZone.run(() => {
    if (this.contactForm.status === 'VALID') {
      console.log('form is valid ' + requestValue);
      const email = this.contactForm.value;
      this.http.post('https://wayu.in/api/email.php', email)
      .subscribe(
        response => {
          console.log(response);
          this.contactForm.reset();
          this.spinner.hide();
        }
      );
      this.spinner.hide();
    } else{
      console.log(requestValue);
      Object.keys(this.contactForm.controls).forEach(field => { // {1}
        const control = this.contactForm.get(field);            // {2}
        control.markAsTouched({ onlySelf: true });       // {3}
      });
      this.spinner.hide();
    }
  });
  }

  isFieldValid(field: string) {
    return !this.contactForm.get(field).valid && this.contactForm.get(field).touched;
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  getSearch() {
    console.log(this.frmSearch.get('searchText').value);
    this.callGapi.isLoad().then((response) => {
      console.log(response);
      this.spinner.show();

      this.loader = this.renderer.selectRootElement('#loader');
      this.renderer.removeClass(this.loader, 'hideSpinner');
      this.renderer.addClass(this.loader, 'showSpinner');
      console.log(this.loader);
      console.log(this.callGapi);
      const loginData = { search_string: this.frmSearch.get('searchText').value, page: 1 };
      // this.response = await this.callGapi.hcpSerach(loginData).toPromise();

      // console.log(this.response);
      // Use the JSON data from the HTTP response

      // console.log(this.response);
      // tslint:disable-next-line:no-shadowed-variable
      this.callGapi.hcpSerach(loginData).then((response: any) => {
        const returnedData = response;
        console.log(returnedData);

        if (returnedData.error_code === 0) {
          this.ngZone.run(() => {
            this.renderer.setStyle(this.loader, 'display', 'none');
            localStorage.setItem('hcps', JSON.stringify(returnedData.hcp_search));
            this.spinner.hide();
            // this.router.navigateByUrl('/search');
            const url = this.router.serializeUrl(
              this.router.createUrlTree(['/search'])
            );
            window.open(url, '_blank');
          });
        } else {
          this.ngZone.run(() => {
            this.renderer.setStyle(this.loader, 'display', 'none');
            this.spinner.hide();
          });
        }
      });

    });
  }
}
