			<!-- Breadcrumb -->
			<div class="breadcrumb-bar" style="margin-top: 10px;">
				<div class="container-fluid">
					<div class="row align-items-center">
						<div class="col-md-8 col-12">
							<nav aria-label="breadcrumb" class="page-breadcrumb">
								<!-- <ol class="breadcrumb">
									<li class="breadcrumb-item"><a href="index-2.html">Home</a></li>
									<li class="breadcrumb-item active" aria-current="page">Search</li>
								</ol> -->
							</nav>
							<h2 class="breadcrumb-title">Matches found for your search</h2>
						</div>
						<div class="col-md-4 col-12 d-md-block d-none">
							<!-- <div class="sort-by">
								<span class="sort-title">Sort by</span>
								<span class="sortby-fliter">
									<select class="select">
										<option>Select</option>
										<option class="sorting">Rating</option>
										<option class="sorting">Popular</option>
										<option class="sorting">Latest</option>
										<option class="sorting">Free</option>
									</select>
								</span>
							</div> -->
						</div>
					</div>
				</div>
			</div>
			<!-- /Breadcrumb -->
			
			<!-- Page Content -->
			<div class="content">
				<div class="container-fluid">

					<div class="row">
						<div class="col-md-12 col-lg-4 col-xl-3 theiaStickySidebar">
						
							<!-- Search Filter -->
							<div class="card search-filter">
								<div class="card-header">
									<h4 class="card-title mb-0">Search Doctors</h4>
								</div>
								<div class="card-body">
								<div class="filter-widget">
									<!-- <div class="cal-icon">
										<input type="text" class="form-control datetimepicker" placeholder="Select Date">
									</div>			 -->
									<div class="search-box">
										<form [formGroup]="frmSearch" (ngSubmit)="getSearch()"> 
											<!-- <div class="form-group search-location">
												<input type="text" class="form-control" placeholder="Search Location">
												<span class="form-text">Based on your Location</span>
											</div> -->
											<div class="form-group">
												<!-- <input type="text" > -->
												<input class="form-control" placeholder="Doctor name, WayuMD ID, speciality or medical conditions" formControlName="searchText" required>
												<div *ngIf="frmSearch.controls['searchText'].invalid && (frmSearch.controls['searchText'].dirty || frmSearch.controls['searchText'].touched)" class="alert alert-danger">
													<div *ngIf="frmSearch.controls['searchText'].errors.required">
													Please enter the search value.
												  </div>
												</div>
										
											</div>
											&nbsp;<button type="submit" [disabled]="frmSearch.pristine || frmSearch.invalid" class="btn btn-primary search-btn"><i class="fas fa-search"></i> <span>Search</span></button>
										</form>
									</div>
									
								</div>
								<!-- <div class="filter-widget">
									<h4>Gender</h4>
									<div>
										<label class="custom_check">
											<input type="checkbox" name="gender_type" checked>
											<span class="checkmark"></span> Male Doctor
										</label>
									</div>
									<div>
										<label class="custom_check">
											<input type="checkbox" name="gender_type">
											<span class="checkmark"></span> Female Doctor
										</label>
									</div>
								</div> -->
								<!-- <div class="filter-widget">
									<h4>Select Specialist</h4>
									<div>
										<label class="custom_check">
											<input type="checkbox" name="select_specialist" checked>
											<span class="checkmark"></span> Urology
										</label>
									</div>
									<div>
										<label class="custom_check">
											<input type="checkbox" name="select_specialist" checked>
											<span class="checkmark"></span> Neurology
										</label>
									</div>
									<div>
										<label class="custom_check">
											<input type="checkbox" name="select_specialist">
											<span class="checkmark"></span> Dentist
										</label>
									</div>
									<div>
										<label class="custom_check">
											<input type="checkbox" name="select_specialist">
											<span class="checkmark"></span> Orthopedic
										</label>
									</div>
									<div>
										<label class="custom_check">
											<input type="checkbox" name="select_specialist">
											<span class="checkmark"></span> Cardiologist
										</label>
									</div>
									<div>
										<label class="custom_check">
											<input type="checkbox" name="select_specialist">
											<span class="checkmark"></span> Cardiologist
										</label>
									</div>
								</div> -->
									<!-- <div class="btn-search">
										<button type="button" class="btn btn-block">Search</button>
									</div>	 -->
								</div>
							</div>
							<!-- /Search Filter -->
							
						</div>
						
						<div class="col-md-12 col-lg-8 col-xl-9">

							<!-- Doctor Widget -->
							<div class="card" *ngFor="let doc of searchResults">
								<div class="card-body">
									<div class="doctor-widget" >
										<div class="doc-info-left">
											<div class="doctor-img">
												<a href="doctor-profile.html">
													<img src="{{doc.serving_url}}" onerror="this.src='/assets/img/doctors/noimage.png';"  class="img-fluid" alt="WayuMd hcp" style="max-height: 150px;min-width: 150px;">
												</a>
												
											</div>
											<div class="doc-info-cont">
												<h4 class="doc-name"><a href="doctor-profile.html">{{doc.title}} {{doc.first_name}} {{doc.last_name}}</a></h4>
												<p class="doc-speciality">{{doc.qualifications}}</p>
												<h5 class="doc-department">
													<!-- <img src="assets/img/specialities/specialities-05.png" class="img-fluid" alt="Speciality"> -->
													{{doc.specialty}}
												</h5>
												<!-- <div class="rating">
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star"></i>
													<span class="d-inline-block average-rating">(17)</span>
												</div> -->
												<!-- <div class="clinic-details">
													<p class="doc-location"><i class="fas fa-map-marker-alt"></i> Florida, USA</p>
													<ul class="clinic-gallery">
														<li>
															<a href="assets/img/features/feature-01.jpg" data-fancybox="gallery">
																<img src="assets/img/features/feature-01.jpg" alt="Feature">
															</a>
														</li>
														<li>
															<a href="assets/img/features/feature-02.jpg" data-fancybox="gallery">
																<img  src="assets/img/features/feature-02.jpg" alt="Feature">
															</a>
														</li>
														<li>
															<a href="assets/img/features/feature-03.jpg" data-fancybox="gallery">
																<img src="assets/img/features/feature-03.jpg" alt="Feature">
															</a>
														</li>
														<li>
															<a href="assets/img/features/feature-04.jpg" data-fancybox="gallery">
																<img src="assets/img/features/feature-04.jpg" alt="Feature">
															</a>
														</li>
													</ul>
												</div> -->
												<!-- <h5 class="doc-department">
													<img src="assets/img/specialities/specialities-05.png" class="img-fluid" alt="Speciality"> 
													Regd#: {{doc.hcp_license_number}}
												</h5>-->
												<!-- <div class="clinic-services">
													<span>{{doc.expertise}}</span>
												</div> -->
												<p class="doc-speciality" style="margin-top:10px;">Expertise: {{doc.expertise}} </p>

												<p class="doc-speciality" style="margin-top:10px;"> Available: {{doc.start_hours}} - {{doc.end_hours}}</p>

												<div class="clinic-services">
													<span *ngFor="let day of doc.available_days" style="padding: 0px;border:none;">
														<span class="btn-day" *ngIf="day !== null && day !== '' && day > 0">
															<b *ngIf="day !== '' && day === '1'"> Mon </b>
															<b *ngIf="day !== '' && day === '2'"> Tue </b>
															<b *ngIf="day !== '' && day === '3'"> Wed </b>
															<b *ngIf="day !== '' && day === '4'"> Thu </b>
															<b *ngIf="day !== '' && day === '5'"> Fri </b>
															<b *ngIf="day !== '' && day === '6'"> Sat </b>
															<b *ngIf="day !== '' && day === '7'"> Sun </b>
														</span>
													</span>
													
												</div>
												
											</div>
										</div>
										<div class="doc-info-right">
											<div class="clini-infos">
												<ul>
													<!-- <li><i class="far fa-thumbs-up"></i> 98%</li>
													<li><i class="far fa-comment"></i> 17 Feedback</li> -->
													<li><i class="fas fa-clinic-medical"></i>{{doc.practice_name}}</li>
													<li style="line-height: 20px;font-size:13px;"><i class="fas fa-map-marker-alt"></i>{{doc.addr_line1}}, {{doc.city}}, {{doc.state}}</li>
													<!--<li><i class="fas fa fa-phone"></i>{{doc.phone}}</li>-->
													<li><i class="far fa-money-bill-alt"></i> INR {{doc.consult_fee}} </li>
												</ul>
											</div>
											<div class="clinic-booking" style="margin-top:10px">
													<!-- <a class="view-pro-btn" href="doctor-profile.html">View Profile</a> -->
													<a class="apt-btn" href="javascript:void(0)" (click)="triggerModal(modalData)" style="padding: 5px;">Consult Now</a>
											</div>
											
										</div>
									</div>
								</div>
							</div>
							<!-- /Doctor Widget -->

							

							
							
							<div class="load-more text-center">
								<a class="btn btn-primary btn-sm" href="javascript:void(0);">Load More</a>	
							</div>	
						</div>
					</div>

				</div>
				
				

<ng-template #modalData let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Download WayuMD</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
	  <p>To consult with this doctor, download the WayuMD mobile up and sign up for free. </p>		
    <ul style="list-style:none;">
									
									<li><a href="https://itunes.apple.com/us/app/wayumd/id1048944622?mt=8" target="_blank"> <img src="assets/img/googleplay_new.png" alt="WayuMD" style="width: 125px;">
									</a> &nbsp;
									<a href="https://play.google.com/store/apps/details?id=com.wayuhealth.patient" target="_blank"> <img src="assets/img/appstore_new.png" alt="WayuMD" style="width: 150px;">
									</a></li>
								</ul>
  </div>
  <!--<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.close('Save click')">Close</button>
  </div>-->
</ng-template>

			</div>		
			
			<!-- /Page Content -->