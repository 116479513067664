import { Component, NgZone, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import {FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-platforms',
  templateUrl: './platforms.component.html',
  styleUrls: ['./platforms.component.css']
})
export class PlatformsComponent implements OnInit {
  title = 'WayuMD Platform | Get a Branded Customised app for your Patients';
  optionsSelect: Array<any>;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  contactForm: FormGroup;
  constructor(private fb: FormBuilder, private http: HttpClient, private spinner: NgxSpinnerService,
              private zone: NgZone, private titleService: Title, private metaTagService: Meta) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Healthcare Technology Platform' }
    );
    this.dropdownList = [
      { item_id: 1, item_text: 'Online clinic and remote consultations' },
      { item_id: 2, item_text: 'Expanding my reach and promoting my practice to new patients' },
      { item_id: 3, item_text: 'Increasing patient engagement and retention' },
      { item_id: 4, item_text: 'Increasing efficiency in my practice operations' },
      { item_id: 5, item_text: 'Providing care and services through digital tools' },
      { item_id: 6, item_text: 'Other' }
    ];
    this.selectedItems = [];

    this.contactForm = this.fb.group({
      name: ['', Validators.required ],
      email: ['', [Validators.required, Validators.email] ],
      mobile: ['', Validators.required ],
      organisation: ['', Validators.required ],
      website: '',
      goal : '',
      message : '',
    });
    // tslint:disable-next-line:no-unused-expression
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      // allowSearchFilter: true
    };
  }
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  public sendRequest(requestValue: any){
    console.log(requestValue);
    this.spinner.show();
    console.log(this.contactForm);
    this.zone.run(() => {
    if (this.contactForm.status === 'VALID') {
      const email = this.contactForm.value;
      this.http.post('https://wayu.in/api/email.php', email)
        .subscribe(
            response => {
              console.log(response);
              this.contactForm.reset();
              this.spinner.hide();
          }
        );
    } else{
      console.log(requestValue);
      Object.keys(this.contactForm.controls).forEach(field => { // {1}
        const control = this.contactForm.get(field);            // {2}
        control.markAsTouched({ onlySelf: true });       // {3}
      });
      this.spinner.hide();
    }
  });
  }

  isFieldValid(field: string) {
    return !this.contactForm.get(field).valid && this.contactForm.get(field).touched;
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth' });
}

}
