<section class="section" id="section-platform-right">
    <div class="container-fluid row" style="margin-right: 0px;padding-right: 0px;">
        <div class="col-md-12 col-lg-6 col-xs-12 col-sm-12 pl-5" style="margin: auto 0;">
            <div class="banner-wrapper">
                
                <div class="banner-header text-left py-4">
                    
                    <h2 class="">A personalized telehealth solution can revolutionize your practice.
                    </h2>
                        <br/>
                        <div class="d-md-block d-lg-none d-xl-none d-xs-block d-sm-block">
                          <img src="../assets/img/main_platform_apps.png" alt="wayu platform" style="width:100%;">
                        </div>
                    <p>As a private practitioner you understand the demands of putting patient needs first, while still maintaining a healthy revenue for your practice. Telehealth solutions delivered under your own brand, with a clear focus on the unique needs and values of your practice, can deliver long term profits and patient satisfaction.</p>
                </div>

                <div class="footer-logo">
                  <div class="row">
                    <button (click)="scroll(getintouch)" role="button" class="btn btn-primary btn-md col-md-6 mx-2"> Launch your own branded app</button>
                    <!--<div class="col-md-3">
                    <a href="https://itunes.apple.com/us/app/wayumd/id1048944622?mt=8"
                      target="_blank" > <img src="assets/img/doc_ios.png" alt="WayuMD for Doctor" />
                    </a>
                    </div>
                    &nbsp;
                    <div class="col-md-3">
                      <a href="https://play.google.com/store/apps/details?id=com.wayuhealth.patient"
                        target="_blank" > <img src="assets/img/doc_android.png" alt="WayuMD for Doctor" />
                      </a>
                    </div>-->
                  </div>
                </div>
                 
                <!-- Search -->
                <!-- <div class="search-box">
                    <form action="templateshub.net">
                        <div class="form-group search-location">
                            <input type="text" class="form-control" placeholder="Search Location">
                            <span class="form-text">Based on your Location</span>
                        </div>
                        <div class="form-group search-info">
                            <input type="text" class="form-control" placeholder="Search Doctors, Clinics, Hospitals, Diseases Etc">
                            <span class="form-text">Ex : Dental or Sugar Check up etc</span>
                        </div>
                        <button type="submit" class="btn btn-primary search-btn"><i class="fas fa-search"></i> <span>Search</span></button>
                    </form>
                </div> -->
                <!-- /Search -->
                
            </div>
        </div>

        <div class="col-md-12 col-lg-6 section-platform-right d-none d-lg-block d-xl-block">

        </div>
        
    </div>
</section>


<div class="h-divider">
    <div class="d-shadow"></div>
</div>

<!-- /Home Banner -->
<!-- Virtual section starts -->
<div class="container" style="margin-top:12rem;">
<section class="section section-features" id="section-virtual" style="margin-bottom: 40px;">
    
<div class="container-fluid">
<!-- <div class="section-header text-left">
    <h2>Virtual Clinic</h2>
    <div class="line"></div>
</div> -->
<div class="row">
    <div class="col-md-12 col-lg-4 relative">
        <div class="banner-wrapper card" style="padding-top:15px">
            <div class="text-center" style="min-height:100px">
                <img src="assets/img/growth.png" alt="Growth" style="width: 160px;position:absolute;
                margin-bottom: 20px;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                top: -25%;
                background: white;
                padding: 8px;
                border-radius: 50%;" class="img-fluid shadow">
            </div>
            <div class="p-3">
              <h3 class="caps text-center" style="font-size: 2rem;">Growth</h3>
              <div class="text-left p-2" style="min-height: 170px;">
                  <p class="text-center" style="font-size:18px">Your app serves as an extension of your practice, & helps you expand your reach, so you can scale up your practice at no added cost.</p>
                  <!-- <br/>
                  <a href="" role="button" class="btn btn-secondary learn-more-btn">Learn More</a> -->
              </div>
            </div>
            
        </div>
    </div>
    <div class="col-md-12 d-md-block d-lg-none d-xl-none d-xs-block d-sm-block m-5 p-2">
      &nbsp;
    </div>
    <div class="col-md-12 col-lg-4 relative">
        <div class="banner-wrapper card" style="padding-top:15px">
            <div class="text-center" style="min-height: 100px;">
                <img src="assets/img/engagement.png" alt="Engagement" style="width: 160px;position:absolute;
                margin-bottom: 20px;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                top: -25%;
                background: white;
                padding: 8px;
                border-radius: 50%;" class="img-fluid shadow">
            </div>
            <div class="p-3">
              <h3 class="caps text-center" style="font-size: 2rem;">Engagement</h3>
              <div class="text-left p-2" style="min-height: 170px;">
                  
                  <p class="text-center" style="font-size:18px">Keeping patients engaged means they will stay within your facility for additional in-patient or high-value services.</p>
                  <!-- <br/>
                  <a href="" role="button" class="btn btn-secondary learn-more-btn">Learn More</a> -->
              </div>
            </div>
            
        </div>
    </div>
    <div class="col-md-12 d-md-block d-lg-none d-xl-none d-xs-block d-sm-block m-5 p-2">
      &nbsp;
    </div>
    <div class="col-md-12 col-lg-4 relative">
        <div class="banner-wrapper card" style="padding-top:15px">
            <div class="text-center" style="min-height: 100px;">
                <img src="assets/img/retention.png" alt="Retention" style="width: 160px;position:absolute;
                margin-bottom: 20px;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                top: -25%;
                background: white;
                padding: 8px;
                border-radius: 50%;" class="img-fluid shadow">
            </div>
            <div class="p-3">
              <h3 class="caps text-center" style="font-size: 2rem;">Retention</h3>
              <div class="text-left p-2" style="min-height: 170px;">
                  <p class="text-center" style="font-size:18px">According to studies, even just a 5% patient retention rate can increase profits by 25% to 95%.</p>
                  <!-- <br/>
                  <a href="" role="button" class="btn btn-secondary learn-more-btn">Learn More</a> -->
              </div>
            </div>
            
        </div>
    </div>
    
</div>

</div>
</section>


<!-- platform section-->


<div class="h-divider" style="position: absolute;
left: 0;">
    <div class="d-shadow"></div>
</div>
<div class="col-md-12 d-md-block d-lg-none d-xl-none d-xs-block d-sm-block m-5">
  &nbsp;
</div>


    <!-- Virtual section starts -->
    <section class="section section-specialities" id="section-virtual">
        <div class="container">
            <div class="container-fluidd">
                <!-- <div class="section-header text-right">
                    <h2>Virtual Clinic</h2>
                    <div class="line" style="float: right;"></div>
                </div> -->
               <div class="row mb-4">
                    <div class="col-md-12 col-lg-8">
                        <h3 class="mb-2" style="font-size:2rem"> Branded apps, built on WayuMD. </h3>
                        <p style="font-size:1.23rem"> Launch your own mobile app, branded and customised for the needs and goals of your practice. The WayuMD suite includes modules for doctors and therapists, as well as your practice operations. Launch feature-rich, sophisticated apps at a fraction of the cost, and quick turnaround time. </p>
                    </div>
                </div>
                <div class="row">

                    <div class="col-md-7 module-section-left">

                    </div>

                    <div class="col-md-5 relative align-self-center">
                        <div class="banner-wrapper">
                            <div class="banner-header text-left">
                                <div class="flex-fill">
                                    <!-- <div class="card-header">
                                        <h4 class="card-title">Text element</h4>
                                    </div> -->
                                    <div class="card-body">

                                        <p><strong>Branded app</strong></p>
                                        <p style="font-size: 15px;padding-top: 12px;">Personalised apps branded to your practice with tools to help patients discover, engage & connect with you.  </p>
                                        <br>
                                         <p><strong>Patient Engagement</strong></p>
                                        <p style="font-size: 15px;padding-top: 12px;">Tools to promote your services, attract new patients, increase patient engagement and retention with memberships and rewards, CRM, and more. </p>
                                        <br>
                                        <p><strong>Practice Management </strong></p>
                                        <p style="font-size: 15px;padding-top: 12px;">Appointments management, billing and invoicing as well as tools to manage your practice operations, inventory, products and services.  </p>
                                        <br>
                                         <p><strong>Consultations and EHR</strong></p>
                                        <p style="font-size: 15px;padding-top: 12px;">Teleconsultation via secure video and chat, customised clinical interaction records, online prescriptions, therapy session notes, and file sharing.   </p>
                                        <br>
                                         <p><strong>Insights & Analytics</strong></p>
                                        <p style="font-size: 15px;padding-top: 12px;">Manage, improve and grow your practice with operational and activity reports, and patient insights.  </p>
                                        <br>
                                         <p><strong>Your storefront </strong></p>
                                        <p style="font-size: 15px;padding-top: 12px;">Launch a digital storefront with a personalised mobile shop, and sell products and services online.   </p>
                                        <br>


                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>


  <div class="h-divider" style="position: absolute; left: 0">
    <div class="d-shadow"></div>
  </div>

  <div class="col-md-12 d-md-block d-lg-none d-xl-none d-xs-block d-sm-block m-5">
    &nbsp;
  </div>

  <!-- Request a demo section-->

  <section class="section section-specialities" id="section-virtual">
    <div class="container">
      <div class="container-fluid" #getintouch>
        <div class="row">
          <div class="col-md-12 offset-md-0">
            
            <section class="mb-4">
              
              <h2 class="h1-responsive font-weight-bold text-center my-4">
                Get in Touch 
              </h2>
              
              <p class="text-center w-responsive mx-auto mb-5">
                Get in touch to learn more about how WayuMD can help you launch your own digital health app and boost your practice.
              </p>

              <div class="row">
                
                <div class="offset-md-2 col-md-9 mb-md-0 mb-5">
                  <form [formGroup]="contactForm" novalidate
                    (ngSubmit)="sendRequest(contactForm.value)" name="contact-form" method="POST">
                   
                    <div class="row mb-2">
                     
                      <div class="col-md-12">
                        <div class="form-outline form-outline">
                          <label class="form-label" for="name" class=""
                            >Your name</label
                          >
                          <input [ngClass]="displayFieldCss('name')"
                            type="text"
                            id="name"
                            name="name"
                            class="form-control"
                            formControlName="name" />
                        </div>
                      </div>
                      
                    </div>
                    <div class="row mb-2">
                      
                      <div class="col-md-6">
                        <div class="form-outline">
                        <label for="email" class="">Your email</label>
                          <input [ngClass]="displayFieldCss('email')"
                            type="email"
                            id="email"
                            name="email"
                            class="form-control"
                            formControlName="email"
                          />
                          
                        </div>
                      </div>
                     
                      <div class="col-md-6">
                        <div class="form-outline">
                        <label for="mobile" class="">Mobile (with country code)</label>
                          <input [ngClass]="displayFieldCss('mobile')"
                            type="text"
                            id="mobile"
                            name="mobile"
                            class="form-control"
                            formControlName="mobile" required
                          />
                          
                        </div>
                      </div>
                    </div>

                    <div class="row mb-2">
                      
                      <div class="col-md-6">
                       <label for="name" class="">Practice Name</label>
                        <div class="form-outline">
                          <input [ngClass]="displayFieldCss('organisation')"
                            type="text"
                            id="name"
                            name="name"
                            class="form-control"
                            formControlName="organisation"
                          />
                         
                        </div>
                      </div>
                     
                      <div class="col-md-6 ">
                        <div class="form-outline">
                        <label for="mobile" class="">Website</label>
                          <input
                            type="text"
                            id="mobile"
                            name="mobile"
                            class="form-control"
                            formControlName="website"
                          />
                          
                        </div>
                      </div>
                     
                    </div>
                    
                    <div class="row mb-2">
                      <div class="col-md-12">
                      <label
                          >What are your main goals for your practice
                          app?</label
                        >
                        <ng-multiselect-dropdown
                          [settings]="dropdownSettings"
                          [data]="dropdownList"
                          [(ngModel)]="selectedItems"
                          formControlName="goal"
                          (onSelect)="onItemSelect($event)"
                          (onSelectAll)="onSelectAll($event)"
                        >
                        </ng-multiselect-dropdown>

                        

                      </div>
                    </div>
                   
                    <div class="row mb-4">
                      
                      <div class="col-md-12">
                       <label for="message">Your message</label>
                        <div class="form-outline">
                          <textarea
                            type="text"
                            id="message"
                            name="message"
                            rows="2"
                            class="form-control md-textarea"
                            formControlName="message"
                          ></textarea>
                         
                        </div>
                      </div>
                    </div>
                   
                    <div class="text-center text-md-left">
                        <button class="btn btn-primary" type="submit" > Submit </button>
                     </div>
                  </form>

                  
                 
                  <div class="status"></div>
                </div>
                
              </div>
            </section>
           
          </div>
        </div>
      </div>
    </div>
  </section>


</div>